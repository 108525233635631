// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.cpbulba-partners {
    background: $background-color;
    padding: 3rem 2rem;
    @include media("tablet", max) {
        padding: 2rem 1rem;
    }
    .wrapper {
        max-width: 1280px;
        margin: auto;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .heading {
            margin: 0;
            a {
                color: $gradient-color1;
            }
        }
        .button {
            font-size: .85rem;
            display: inline-block;
            color: $gradient-color1;
            border: 1px solid $gradient-color1;
            padding: .5rem;
            border-radius: .25rem;
            &:hover {
                background: rgba($gradient-color1, 0.1);
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        @include media("tablet", max) {
            flex-wrap: wrap;
            margin: -.5rem;
        }
        li {
            flex: 1;
            display: flex;
            box-sizing: border-box;
            @include media("tablet", min) {
                &:not(:last-child) {
                    margin-right: 2rem;
                }
            }
            @include media("tablet", max) {
                flex-basis: calc(100%/3);
                padding: .5rem;
                /* IE ONLY */
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex-basis: calc(100%/3 - 1.1rem);
                }
            }
            @include media("phone", max) {
                flex-basis: 50%;
                /* IE ONLY */
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex-basis: calc(50% - 1.1rem);
                }
            }
            img {
                width: 100%;
                border-radius: .5rem;
            }
        }
    }
}